<template>
  <v-col style="height:100%" cols="12" class="d-flex justify-center align-center">
    <i class="mdi mdi-lock-alert mdi-48px" />
    <h1>
      You don’t have permission to view this page
    </h1>
  </v-col>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
